import $ from 'jquery'

console.log('new activities')

$(function(){

  function isAnyIe() {
    let isIE = false;
    const ua = window.navigator.userAgent;
    const old_ie = ua.indexOf('MSIE ');
    const new_ie = ua.indexOf('Trident/');

    if ((old_ie > -1) || (new_ie > -1)) {
        isIE = true;
    }
    return isIE;
  }

  const actions = {
    print_html_document: function(options){
      if(isAnyIe()){
        console.log('ie..')
        window.open(options.url, '_blank')
      } else {
        var a = document.createElement('a');
        a.download = options.filename;
        a.type = 'application/pdf';
        a.href = options.url;
        a.click();
      }
    },
    download_file: function(options){
      window.location = options.url
    },
    execute_javascript: function(options){
      eval(options.script)
    },
    notify_user: function(options){
      alert(options.message)
    },
    redirect_to: function(options){
      window.location = options.url
    },
  }

  var activityLocks = {}

  class ActivityLocker {
    constructor(activityCode, data) {
      this.activityCode = activityCode
      this.data = data;
      this.getLockerKey()
    }

    getLockerKey(){
      this.lockerKey = this.activityCode + "_" + (JSON.stringify(this.data))
    }

    isLocked(){
      if (activityLocks[this.lockerKey]){
        console.log("locked: "+this.lockerKey)
        return true;
      } else {
        console.log("unlocked: "+this.lockerKey)
        console.log(activityLocks)
        return false;
      }
    }

    lock(){
      console.log("lock: "+this.lockerKey)
      activityLocks[this.lockerKey] = true;
    }

    unlock(){
      console.log("unlock: "+this.lockerKey)
      delete activityLocks[this.lockerKey];
    }
  }

  const formDataToJson = function(form){
    var array = $(form).serializeArray()
    var object = {}
    $.each(array, function(index, item){
      object[item.name] = item.value
    })
    return object
  }

  const runActivity = function(activityCode, data){
    var locker = new ActivityLocker(activityCode, data)

    if (locker.isLocked()){
      console.warn("activity locked: " + activityCode);
      return;
    }

    locker.lock();

    $.ajax({
      type: "POST",
      url: '/activities',
      beforeSend: function(xhr) {
        xhr.setRequestHeader(
          'X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')
        )
      },
      data: $.extend({}, data, {
        code: activityCode,
        activity_code: activityCode // for debug ("code" param filtered in logs)
      }),
      error: function(response){
        locker.unlock();
      },
      success: function(response){
        locker.unlock();

        $.each(response, function(index, action_config){

          var method = actions[action_config.action]
          if(method){
            method(action_config.options)
          }
        })
      },
      dataType: 'json'
    })
  }

  $('.sign-out').click(function(e){
    $.ajax({
      type: 'DELETE',
      url: '/auth/sign_out',
      success: function(response){
        document.location.href = '/'
      }
    })
  })

  $('form[data-activity-form-code]').on('submit', function(e){
    e.preventDefault()
    var activityCode = $(this).data('activity-form-code')
    var activityOptions = formDataToJson(this)
    runActivity(activityCode, activityOptions)
    return false;
  })

  $('a[data-activity-code]').on('click', function(e){
    e.preventDefault()
    var activityCode = $(this).data('activity-code')
    var activityOptions = $(this).data('activity-options')
    if (!activityOptions){
      activityOptions = {}
    }
    runActivity(activityCode, activityOptions)
    return false;
  })
})
