var version = null;

if(document.location.search.match(/preview_theme_id/)){
  setInterval(
    function(){
      fetch('/metadata').then(
        function(response){
          response.json().then(
            function(data){
              if(version !== null && version < data.build_version){
                document.location.reload()
              }

              version = data.build_version
            }
          )
        }
      )
    },
    2000
  )
}
